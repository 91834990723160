<template>
	<div>
		<!-- <h2>Ejemplares</h2> -->
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: "InventorySpecimensMain",
	data() {
		return {};
	},
	mounted() {
		// this.isPreloading();
	}
};
</script>

<style scoped lang="scss"></style>
